import React from "react";

const Title = ({ text }) => {
  return (
    <>
      <h1 className="h1 text-center py-6 sm:py-2 xs:py-2">
        <span className="text-accent">{text}</span>
      </h1>
    </>
  );
};

export default Title;
