import React from "react";
import Image from "../../assets/images/homepage.jpg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeIn } from "../../variants";

const Home = () => {
  return (
    <div class="relative h-full w-full bg-gradient-to-r from-zinc-950 via-stone-500 to-stone-500">
      <img
        src={Image}
        alt="5th Wheel Towing"
        className="object-cover w-full h-full absolute mix-blend-overlay "
      />
      <motion.div
        variants={fadeIn("right", 0.2)}
        initial="hidden"
        animate="show"
        exit="hidden"
        className="absolute top-1/4 transform xl:-translate-x-80 lg:-translate-x-60 lg:-translate-y-20 md:translate-x-30 sm:-translate-x-20 sm:-translate-y-20 xs:-translate-x-10 xs:-translate-y-10 xl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 xs:w-1/2 text-left ml-8"
      >
        <h1 className="text-white font-medium xl:text-5xl lg:text-5xl md:text:3xl sm:text-xl xs:text-xl">
          Expert Travel Trailer Towing Service
        </h1>
        <div className="xl:h-1 lg:h-1 md:h-0.5 sm:h-0.5 xs:h-0.5 lg:w-32 md:w-20 sm:w-12 xs:w-12 bg-accent my-4 md:my-2 sm:my-1 xs:my-1"></div>
        <p className="xl:text-xl lg:text-lg md:text-md sm:text-sm text-white font-normal text-left ">
          With years of experience and a dedication to safety, we provide
          reliable towing solutions for your travel trailer, ensuring a smooth
          journey every time.
        </p>
        <div className="xl:mt-10 sm:mt-4 xs:mt-4">
          <Link
            to="/quote"
            className="left-1/4 transform xl:-translate-x-80 xl:-translate-y-20 lg:-translate-x-80 lg:-translate-y-20 md:-translate-x-40 sm:-translate-x-20 sm:-translate-y-20 xs:-translate-x-10 xl:w-96 text-left bg-primary text-white xl:text-xl lg:text-lg md:text-md sm:text-sm rounded hover:text-accent hover:bg-opacity-95 shadow-sm hover:shadow-xl lg:py-4 lg:px-8 md:py-2 md:px-6 sm:py-2 sm:px-4 xs:py-2 xs:px-4"
          >
            Get a Quote
          </Link>
        </div>
      </motion.div>
    </div>
  );
};

export default Home;
