import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const NavLinks = ({ links }) => {
  const location = useLocation();
  return (
    <>
      {links.map((link) => (
        <NavLink
          key={link.to}
          to={link.to}
          className={`text-xl font-medium transition-colors duration-300 hover:text-accent ${
            location.pathname === link.to
              ? "text-accent underline"
              : "text-white"
          } `}
        >
          {link.title}
        </NavLink>
      ))}
    </>
  );
};

export default NavLinks;
