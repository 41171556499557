import towingImage from "../../assets/images/tonguePullTrailer.jpg";
import Title from "../../components/Title/Title";

const About = () => {
  return (
    <>
      <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 md:placeholder: gap-x-16 md:gap-y-8 xl:px-12 lg:px:12 md:px-8 sm:px-6 xs:px-6 sm:py-6 xs:py-6 py-10 mt-10 sm:mt-4 xs:mt-4">
        <div className="hidden sm:block rounded shadow-xl md:col-span-2 xl:col-span-1 lg:col-span-1">
          <img
            src={towingImage}
            alt="Contact"
            className="object-cover w-full h-full rounded"
          />
        </div>
        <div className="col-span-1 border-none border-2 rounded-lg shadow-xl shadow-green-800 bg-white md:col-span-2 sm:col-span-2 xs:col-span-2 lg:col-span-1">
          <Title text="Who We Are..." />
          <p className="p px-8 pb-3  text-center first-line:tracking-widest first-letter:text-2xl first-letter:font-bold first-letter:text-slate-900 text-pretty first-line:uppercase">
            Welcome to Dyer Towing & Services, your premier destination for
            expert travel trailer towing services. We specialize in safely and
            efficiently moving a variety of trailers, including tongue-pull,
            fifth-wheel, and gooseneck. With years of experience and a
            dedication to customer satisfaction, we pride ourselves on being the
            go-to choice for travel trailer owners in need of reliable towing
            solutions.
          </p>
          <p className="p px-8 text-center first-line:tracking-widest first-letter:text-2xl first-letter:font-bold first-letter:text-slate-900 text-pretty first-line:uppercase">
            We understand the importance of your investment and treat each
            trailer with the utmost care and attention to detail. Whether you're
            relocating to a new destination, heading out on an adventure, or
            need assistance with a trailer transport project, you can trust us
            to handle your trailer with precision and expertise. Rest assured
            that your trailer is in good hands and will arrive at its
            destination safely and on time. Contact us today to learn more about
            our services and how we can help you with your travel trailer towing
            needs.
          </p>
          <Title text="Our Mission..." />
          <p className="p px-8 pb-10 text-center first-line:tracking-widest first-letter:text-2xl first-letter:font-bold first-letter:text-slate-900 text-pretty first-line:uppercase">
            Our mission is to provide exceptional service and peace of mind to
            every client we serve. We are committed to delivering safe, reliable
            transport solutions that meet your unique needs. We strive to exceed
            the expectations of our clients by delivering efficient, timely, and
            professional services. With a focus on integrity, innovation, and
            excellence, we aim to be the preferred choice for all towing and
            transport requirements, fostering long-term relationships built on
            trust and exceptional service.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
