import React, { useEffect, useState } from "react";

const Maps = ({ directionsService, directionsRenderer }) => {
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (window.google && window.google.maps) {
      // Initialize map
      const newMap = new window.google.maps.Map(
        document.getElementById("map"),
        {
          zoom: 8,
          center: { lat: 31.721, lng: -96.158 },
          mapTypeControl: false,
        }
      );
      setMap(newMap);

      if (directionsRenderer) {
        directionsRenderer.setMap(newMap);
      }
    }
  }, [directionsRenderer]);

  // Needs to refresh until map shows itself ** FIX **
  function calculateAndDisplayRoute() {
    if (!map || !directionsRenderer) {
      console.error("Map or directions renderer is not initialized yet.");
      return;
    }
  }

  return (
    <div className="w-full p-10 sm:p-4 xs:p-4 sm:mx-0 xs:mx-0 mx-4 shadow-md rounded-md justify-center bg-white">
      <h2 className="h2 text-center"> </h2>
      <div className="flex items-center m-4 text-center text-green-600 text-xl font-medium">
        <div id="distance"></div>
      </div>
      <div id="map" className="w-full h-96 rounded-md">
        Map
      </div>
    </div>
  );
};

export default Maps;
