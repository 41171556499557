import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home.jsx";
import About from "./pages/About/About.jsx";
import Services from "./pages/Services/Services.jsx";
import ContactMe from "./pages/ContactMe/ContactMe.jsx";
import Layout from "./components/App-Routes-Layout/Layout.jsx";
import QuotePage from "./pages/QuoteForm/QuotePage.jsx";
import ContextRoute from "../src/Context/ContextRoute.jsx";

const App = () => {
  return (
    <Routes>
      <Route element={<ContextRoute />}>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/quote" element={<QuotePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<ContactMe />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
