import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { Outlet } from "react-router-dom";

const Layout = () => (
  <div className="flex flex-col h-screen">
    <header className="bg-gradient-to-br from-slate-100 from-10% via-primary via-50% to-black px-6 ">
      <Navbar />
    </header>
    <div className="flex-1 overflow-y-auto">
      <Outlet />
    </div>
    <Footer />
  </div>
);

export default Layout;
