import React from "react";
import { HiOutlineMail, HiOutlinePhone } from "react-icons/hi";

const ContactInfo = () => {
  return (
    <div className=" flex flex-col justify-center items-center sm:pb-2 xs:pb-2">
      <h2 className="h2 text-accent sm:text-center xs:text-center sm:py-1 xs:p-1">
        Please reach out for any questions on Pricing
      </h2>
      <div className="flex flex-col justify-center items-center sm:py-1 xs:p-1">
      <HiOutlineMail className="text-4xl" />
      <p className="text-green-600 lg:text-xl md:text-lg sm:text-sm xs:text-sm">
        <a href="mailto:dyer.towing@gmail.com" className="hover:text-green-700">
        dyer.towing@gmail.com
        </a>
      </p>
      </div>
      <div className="flex flex-col justify-center items-center sm:py-1 xs:p-1">
      <HiOutlinePhone className="text-4xl" />
      <p className="text-green-600 lg:text-xl md:text-lg sm:text-md xs:text-sm">
        <a href="tel:14803432950" className="hover:text-green-700">
          (903)-519-3838
        </a>
      </p>
      </div>
    </div>
  );
};

export default ContactInfo;
