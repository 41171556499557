import React from "react";
import { Link } from "react-router-dom";
import { HiOutlineMail, HiOutlinePhone } from "react-icons/hi";
import { HiOutlineMapPin } from "react-icons/hi2";

const Footer = () => {
  return (
    <>
      <div className="p-4 flex justify-between bg-gradient-to-br from-slate-100 from-1% via-primary via-50% to-black">
        <div className="flex items-center">
          <a href="mailto:dyer.towing@gmail.com"
            className="text-white px-1 font-normal hover:text-accent xl:text:md sm:text-sm xs:text-xs"
          >
            <HiOutlineMail />
          </a>
          <a href="mailto:dyer.towing@gmail.com"
            className="text-white px-1 font-normal  hover:text-accent xl:text:md sm:text-sm xs:text-xs hidden sm:block"
          >
            dyer.towing@gmail.com
          </a>
        </div>
        <div className="flex items-center">
          <a href="tel:19035193838"
            to="/contact"
            className="text-white px-1 font-normal xl:text:md sm:text-sm xs:text-xs"
          >
            <HiOutlinePhone className="hover:text-accent" />
          </a>
          <p className="text-white px-1 font-medium">
            <a href="tel:19035193838" className=" hover:text-accent">
              (903)-519-3838
            </a>
          </p>
        </div>
        <div className="flex items-center ">
          <Link
            to="/quote"
            className="text-white px-1 font-normal  hover:text-accent xl:text:md sm:text-sm xs:text-xs"
          >
            <HiOutlineMapPin className="flex-initial text-white font-medium hover:text-accent" />
          </Link>
          <Link
            to="/quote"
            className="text-white font-normal  hover:text-accent xl:text:md sm:text-sm xs:text-xs hidden sm:block"
          >
            Fairfield, Texas
          </Link>
        </div>
      </div>
    </>
  );
};

export default Footer;
