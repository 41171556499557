import React, { useEffect, useState } from "react";
import Use_Window_Resize from "../../Context/window-resizing/resizing";
import { Link } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import Logo from "../../assets/logos/DyerTowGreyLogo.png";
import NavLinks from "../Navlinks/NavLinks";

const links = [
  { to: "/", title: "Home" },
  { to: "/About", title: "About" },
  { to: "/Services", title: "Services" },
  { to: "/Contact", title: "Contact" },
];

export default function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);

  const windowSize = Use_Window_Resize();
  const { width } = windowSize;

  function handleToggleMenu() {
    setToggleMenu((prev) => !prev);
  }

  useEffect(() => {
    if (width >= 1001) {
      setToggleMenu(false); 
    }
  }, [width]);

  return (
    <nav className="bg-transparent px-6 sm:px-0 xs:px-0">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <img className="h-[10em]" src={Logo} alt="Dyer Logo" />
          <p className="max-[600px]:hidden text-xl text-white font-medium whitespace-nowrap">
            Dyer Towing & Services
          </p>
        </div>
        <div className="min-[1001px]:hidden ">
          <button
            onClick={handleToggleMenu}
            className="px-3 py-2 bg-white text-gray-800 rounded hover:bg-gray-200"
          >
            {toggleMenu ? <FiX size={20} /> : <FiMenu size={20} />}
          </button>
        </div>
        <div className="max-[1000px]:hidden flex items-center gap-4">
          <NavLinks links={links} textColor="text-white" />
          <Link
            to="/quote"
            className="px-3 py-2 bg-white text-xl text-gray-800 rounded hover:text-accent hover:bg-gray-100"
          >
            Quote
          </Link>
        </div>
      </div>
      {toggleMenu && (
        <div
          className="fixed inset-0 z-50 bg-gray-900 bg-opacity-90 flex justify-center items-center"
          onClick={handleToggleMenu}
        >
          <div className="flex flex-col gap-4 items-center">
            <NavLinks links={links} textColor="text-black" />
            <Link
              to="/quote"
              className="block mt-4 px-3 py-2 bg-primary text-white text-xl rounded hover:text-accent hover:bg-opacity-95"
            >
              Quote
            </Link>
          </div>
          <button
            onClick={handleToggleMenu}
            className="absolute top-0 right-0 mt-2 mr-2"
          ></button>
        </div>
      )}
    </nav>
  );
}
