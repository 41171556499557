import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./global.css";
import { BrowserRouter as Router } from "react-router-dom";
import { DistanceProvider } from "./Context/Context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <DistanceProvider>
      <App />
    </DistanceProvider>
  </Router>
);
