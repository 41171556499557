import React, { useState } from "react";
import Basic from "../../assets/icons/caravan.png";
import Inclusive from "../../assets/icons/premium-quality.png";
import Lockout from "../../assets/icons/open-padlock.png";
import Axle from "../../assets/icons/development.png";
import Roadside from "../../assets/icons/flat-tire.png";
import Utility from "../../assets/icons/plug.png";
import Wheel from "../../assets/icons/wheel.png";
import Title from "../../components/Title/Title";
import PackageCard from "../../components/Card/PackageCard";
import ContactInfo from "../../components/ContactInfo/ContactInfo";

const Services = () => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <>
      <Title text="Services" />
      <div className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-8 px-12 sm:px-6 xs:px-6">
        <PackageCard
          key={"front"}
          bgImg={Basic}
          title={"Basic Service"}
          insidePrice={"$200"}
          insideColor={"*Inside Freestone County"}
          insideText={"Pick up and Drop off both within Freestone County"}
          outsidePrice={"$350"}
          outsideColor={"*Outside Freestone County"}
          outsideText={
            "First 100 miles (this includes distance to pick up site)"
          }
          handleClick={() => setIsFlipped(!isFlipped)}
          descriptionBack={
            "Tailored for clients who prefer to handle setup and utilities themselves.\n Upon driver arrival:\n Trailer is travel ready, Slides in, Stabilizers up, Utilities disconnected. \n Upon destination arrival: \n No utility hookup, No leveling, No setup."
          }
        />
        <PackageCard
          key={"front"}
          bgImg={Inclusive}
          title={"All Inclusive Service"}
          insidePrice={"$250"}
          insideColor={"*Inside Freestone County"}
          insideText={"Pick up and Drop off both within Freestone County"}
          outsidePrice={"$400"}
          outsideColor={"*Outside Freestone County"}
          outsideText={
            "First 100 miles (this includes distance to pick up site)"
          }
          handleClick={() => setIsFlipped(!isFlipped)}
          descriptionBack={
            "From start to finish, we handle every detail to ensure your trailer is ready for the road.\n Upon driver arrival:\n Bring in slides, disengage utilities, and conduct a thorough inspection and greasing of hubs in all axles.\n Upon destination arrival:\n Full utility hook up, trailer leveling, bring slides out into open position, full set up. This means you can simply walk in and your trailer is ready to live in and enjoy."
          }
        />
        <PackageCard
          key={"front"}
          bgImg={Wheel}
          title={"All Transports"}
          insideColor={"*First 50 miles to Pick up Point is on us."}
          price={"$6.00"}
          descriptionBack={
            "Distance beyond 50 miles to pick up point will count towards the 100 mile allotment included in the package. Any mileage after the 100 mile allotment is $6.00 per mile"
          }
        />
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-8 p-12 mt-4 sm:px-6 xs:px-6">
        <PackageCard
          key={"front"}
          bgImg={Axle}
          title={"Axle Check"}
          price={"$35"}
          insideColor={"*Only Applicable to Basic Transport Package"}
          handleClick={() => setIsFlipped(!isFlipped)}
          descriptionBack={
            "We understand the importance of maintaining the safety and reliability of your trailer. That's why we offer an additional service to ensure your peace of mind on the road. Our Axle Service includes a thorough inspection of your trailer's axles, as well as a detailed examination and greasing of the bearings in all axles. By proactively addressing potential issues with your trailer's axles and bearings, we help prevent costly breakdowns and ensure smooth travels."
          }
        />
        <PackageCard
          key={"front"}
          bgImg={Utility}
          title={"Utility Service"}
          price={"$20"}
          insideColor={"*Only Applicable to Basic Transport Package"}
          handleClick={() => setIsFlipped(!isFlipped)}
          descriptionBack={
            "We understand that setting up and disconnecting utilities can be a hassle, that's why we offer a Utiliy Service to take the stress out of managing your trailer's essential connections. Unhooking black and grey tanks, draining hoses, unhooking water supply and power cord. Upon destination arrival, we will hook back up all your utilities, ensuring a seamless transition and allowing you to settle in without delay."
          }
        />
        <PackageCard
          key={"front"}
          bgImg={Roadside}
          title={"Roadside Flat"}
          price={"$50"}
          insideColor={"*Only Applicable to Transport Packages"}
          handleClick={() => setIsFlipped(!isFlipped)}
          descriptionBack={
            "If there is a blowout or flat tire on the road, our team will utilize your trailer's spare tire to get you back on track. In the event that your trailer doesn't have a spare tire or it has already been used, We'll cover the cost of up to 2 additional flats out of pocket. Please Note: Any tire replacements covered by our copmpany will be added to your bill, with payment due upon delivery."
          }
        />
      </div>
      <div className="grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-8 m-10 sm:m-4 xs:m-4 p-8 sm:p-2 xs:p-2">
        <PackageCard
          bgImg={Lockout}
          title={"Vehicle Lockout Service"}
          coverText1={"Coverage area"}
          coverRadius={"80"}
          coverText2={"mile radius of Fairfield TX"}
          insidePriceReg={"$75"}
          insidePriceHol={"$125"}
          insideColor={"*Inside Freestone County"}
          outsideColor={"*Outside Freestone County"}
          outsidePriceReg={"$150"}
          outsidePriceHol={"$200"}
          hoursReg={"9am-5pm Monday-Friday Excluding Holidays"}
          hoursHol={"After Hours, Weekends and Holidays"}
          handleClick={() => setIsFlipped(!isFlipped)}
          descriptionBack={
            "We specialize in providing fast and reliable vehicle lockout assistance, without the need for towing.\n Whether you've accidentally locked your keys inside your car, misplaced them, or experienced a malfunction with your vehicle's locking system, our team is here to help.\n With our expertise and state-of-the-art equipment, we can quickly and safely unlock your vehicle, allowing you to get back on the road in no time."
          }
        />
        <ContactInfo />
      </div>
    </>
  );
};

export default Services;
