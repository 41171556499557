import React, { useEffect, useState } from "react";
import { useDistance } from "../../Context/Context";
import Map from "../../components/Map/Map";
import QuoteForm from "./QuoteForm";
import QuoteModal from "./QuoteModal";
import ContactInfo from "../../components/ContactInfo/ContactInfo";

const QuotePage = () => {
  const { updateDistance } = useDistance();
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);

  useEffect(() => {
    if (window.google && window.google.maps) {
      const newDirectionsService = new window.google.maps.DirectionsService();
      setDirectionsService(newDirectionsService);

      const newDirectionsRenderer = new window.google.maps.DirectionsRenderer();
      setDirectionsRenderer(newDirectionsRenderer);
    } else {
      console.error("Google Maps API not loaded");
    }
  }, []);

  function calculateDistance() {
    const start = document.getElementById("start").value;
    const end = document.getElementById("end").value;

    directionsService
      .route({
        waypoints: [
          {
            location: start,
            stopover: false,
          },
          {
            location: end,
            stopover: true,
          },
        ],
        origin: "187 E US Highway 84, Fairfield,TX",
        destination: "187 E US Highway 84, Fairfield,TX",
        travelMode: window.google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        directionsRenderer.setDirections(response);

        const distance = response.routes[0].legs[0].distance.text;
        const duration = response.routes[0].legs[0].duration.text;
        updateDistance(distance);

        document.getElementById(
          "distance"
        ).innerText = `Distance: ${distance}, Duration: ${duration}`;

        clearInputFields();
      })
      .catch((e) =>
        window.alert("Directions request failed due to " + e.status)
      );
  }

  function clearInputFields() {
    document.getElementById("start").value = "";
    document.getElementById("end").value = "";
  }

  const { showQuoteModal, totalQuote } = useDistance();

  return (
    <>
      <div className="grid xl:grid-cols-2 lg:col-span-1 md:col-span-1 sm:col-span-1 px-10 xs:px-6 py-6 ">
        {showQuoteModal && <QuoteModal />}
        <div className="w-full md:col-span-2 sm:col-span-2 lg:col-span-2 xl:col-span-1">
          <div className="lg:block" id="output">
            <QuoteForm updateDistance={calculateDistance} />
          </div>
        </div>
        <div className="flex flex-col justify-items-center lg:block md:col-span-2 sm:col-span-2 lg:col-span-2 xl:col-span-1 mt-10">
          <Map
            directionsService={directionsService}
            directionsRenderer={directionsRenderer}
          />
          <ContactInfo />
        </div>
      </div>
    </>
  );
};

export default QuotePage;
