import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";

const PackageCard = ({
  handleClick,
  bgImg,
  title,
  descriptionFront,
  descriptionBack,
  price,
  insidePrice,
  insideColor,
  insideText,
  insidePriceReg,
  insidePriceHol,
  outsidePriceReg,
  outsidePriceHol,
  outsidePrice,
  outsideColor,
  outsideText,
  coverText1,
  coverText2,
  coverRadius,
  hoursReg,
  hoursHol,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      <div className="flex flex-col border-none border-2 rounded-lg shadow-xl shadow-green-800 bg-white items-center p-4 justify-center text-center front-card h-96 w-auto">
        <div className="w-20">
          <img src={bgImg} alt="Icon" />
        </div>
        <h2 className="h2 text-accent">{title}</h2>
        <p>{descriptionFront}</p>
        <p>
          {coverText1}
          <strong>{coverRadius}</strong>
          {coverText2}
        </p>
        <p className="text-green-600 text-center">{insideColor}</p>
        <p>
          <strong>{price}</strong>
        </p>
        <div className="flex flex-row gap-1 ">
          <p>
            <strong>{insidePriceReg}</strong>
          </p>
          <p>{hoursReg}</p>
        </div>
        <div className="flex flex-row gap-1">
          <p>
            <strong>{insidePriceHol}</strong>
          </p>
          <p>{hoursHol}</p>
        </div>
        <p>
          <strong>{insidePrice}</strong>
        </p>
        <p> {insideText}</p>
        <p className="text-green-600">{outsideColor}</p>
        <div className="flex flex-row gap-1">
          <p>
            <strong>{outsidePriceReg}</strong>
          </p>
          <p>{hoursReg}</p>
        </div>
        <div className="flex flex-row gap-1">
          <p>
            <strong>{outsidePriceHol}</strong>
          </p>
          <p>{hoursHol}</p>
        </div>
        <p>
          <strong>{outsidePrice}</strong>
        </p>
        <p>{outsideText}</p>
        <div className="p-2">
          <button
            className="btn bg-primary text-white hover:bg-opacity-90 hover:text-accent p-2 rounded w-64 text-xl text-center font-normal"
            onClick={(handleClick = () => setIsFlipped(!isFlipped))}
          >
            Package Details
          </button>
        </div>
      </div>
      <div className="flex flex-col border-none border-2 rounded-lg shadow-xl shadow-green-800 items-center bg-white p-8 back-card h-84 w-auto">
        <div className="w-20">
          <img src={bgImg} alt="Icon" />
        </div>
        <h2 className="h2 font-semibold">
          <span className="text-accent">{title}</span>
        </h2>
        <div className="flex flex-col items-center justify-between">
          <p className="text-center first-line:tracking-widest first-letter:text-2xl first-letter:font-bold first-letter:text-slate-900 text-pretty first-line:uppercase">
            {descriptionBack}
          </p>
          <div className="p-2">
            <button
              className="btn bg-primary text-white hover:bg-opacity-90 hover:text-accent p-2 rounded w-64 text-xl text-center font-normal"
              onClick={handleClick}
            >
              Package Price
            </button>
          </div>
        </div>
      </div>
    </ReactCardFlip>
  );
};

export default PackageCard;
