import React, { createContext, useContext, useState } from "react";

const DistanceContext = createContext();

export const useDistance = () => useContext(DistanceContext);

export const pricing = {
  basicSvc: 200,
  allInclusiveSvc: 350,
  axleCheck: 35,
  roadSideFlat: 50,
  utiltySvc: 20,
  lockoutSvc: 75,
  lockoutSvcOut: 125,
};

export const DistanceProvider = ({ children }) => {
  const [distance, setDistance] = useState("");
  const [showQuoteModal, setShowQuoteModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedAdditionalServices, setSelectedAdditionalServices] = useState(
    []
  );
  const [selectedLockoutService, setSelectedLockoutService] = useState(false);
  const [selectedLockoutServiceOut, setSelectedLockoutServiceOut] =
    useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const [totalQuote, setTotalQuote] = useState(0);
  const [costPerMile, setCostPerMile] = useState(0);
  const [serviceDistance, setServiceDistance] = useState(0);

  const updateDistance = (newDistance) => {
    setDistance(newDistance);
  };

  return (
    <DistanceContext.Provider
      value={{
        distance,
        updateDistance,
        showQuoteModal,
        setShowQuoteModal,
        selectedService,
        setSelectedService,
        selectedAdditionalServices,
        setSelectedAdditionalServices,
        selectedLockoutService,
        setSelectedLockoutService,
        selectedLockoutServiceOut,
        setSelectedLockoutServiceOut,
        totalQuote,
        setTotalQuote,
        totalCost,
        setTotalCost,
        costPerMile,
        setCostPerMile,
        serviceDistance,
        setServiceDistance,
      }}
    >
      {children}
    </DistanceContext.Provider>
  );
};
