import React, { useEffect, useRef } from "react";
import { useDistance, pricing } from "../../Context/Context";
import { Link } from "react-router-dom";
import Title from "../../components/Title/Title";
import { TfiClose } from "react-icons/tfi";

const QuoteModal = ({}) => {
  const {
    setShowQuoteModal,
    selectedService,
    selectedAdditionalServices,
    selectedLockoutService,
    selectedLockoutServiceOut,
    setSelectedService,
    setSelectedAdditionalServices,
    setSelectedLockoutService,
    setSelectedLockoutServiceOut,
    totalQuote,
    distance,
    totalCost,
    setTotalCost,
    updateDistance,
  } = useDistance();

  const {
    basicSvc,
    allInclusiveSvc,
    axleCheck,
    roadSideFlat,
    utiltySvc,
    lockoutSvc,
    lockoutSvcOut,
  } = pricing;

  const perMileRef = useRef(null);
  const regex = /[\d,]+/;
  const match = distance.match(regex);

  const calculateServiceDistance = (distanceRegex) => {
    if (distanceRegex > 100) {
      return (distanceRegex - 100) * 6 * 1;
    } else {
      return 0;
    }
  };

  function handleNewQuote() {
    setShowQuoteModal(false);
    setSelectedService(null);
    setSelectedAdditionalServices([]);
    setSelectedLockoutService(false);
    setSelectedLockoutServiceOut(false);
    updateDistance("");
  }

  useEffect(() => {
    let distanceRegex;
    if (match) {
      distanceRegex = match[0].replace(/,/g, "");
    } else {
      console.log("No valid number found.");
    }

    let perMile = calculateServiceDistance(parseInt(distanceRegex));
    perMileRef.current = perMile;
    let total = (parseFloat(perMileRef.current) + totalQuote).toFixed(2);
    setTotalCost(total);
  }, [distance, totalQuote, setTotalCost, perMileRef, match]);

  return (
    <>
      <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-50">
        <div className="relative my-6 mx-auto max-w-3xl bg-white xl:w-6/12 sm:w-3/4 xs:w-3/4 p-6">
          <div>
            <button
              className="float-right text-xl"
              onClick={() => setShowQuoteModal(false)}
            >
              <TfiClose />
            </button>
            <div className="grid justify-items-center">
              <Title text="Estimated Quote" />
            </div>
            <div>
              <div className="container-fluid">
                <h4 className="text-green-600 font-medium">Main Service:</h4>
                {selectedService &&
                  (selectedService === "Lockout Service" ? (
                    <p>{selectedService} </p>
                  ) : (
                    <p>
                      {selectedService} : $
                      {selectedService === "Basic Transport"
                        ? basicSvc
                        : allInclusiveSvc}
                    </p>
                  ))}
                <h4 className="text-green-600 font-medium">
                  Additional Services:
                </h4>
                {Array.isArray(selectedAdditionalServices) &&
                  selectedAdditionalServices.length > 0 &&
                  selectedAdditionalServices.map((selectedService) => (
                    <p key={selectedService}>
                      {selectedService === "Axle Check"
                        ? `${selectedService} : $${axleCheck}`
                        : selectedService === "Road Side Flat"
                        ? `${selectedService} : $${roadSideFlat}`
                        : selectedService === "Utility Service"
                        ? `${selectedService} : $${utiltySvc}`
                        : null}
                    </p>
                  ))}
                {selectedLockoutService && (
                  <p>Inside Freestone County: ${lockoutSvc}</p>
                )}
                {selectedLockoutServiceOut && (
                  <p>Outside Freestone County: ${lockoutSvcOut}</p>
                )}
              </div>
              <hr />
              <p>
                <span className="text-green-600"> Quote Total:</span> $
                {totalQuote}
              </p>
              <p>
                <span className="text-green-600">Service Distance:</span> $
                {perMileRef.current}
              </p>
              <hr className="p-1 rounded bg-secondary" />
              <div className="text-center p-4">
                <p className="font-bold xl:text-xl">
                  <span className="text-green-600">Estimated Total:</span> $
                  {totalCost}
                </p>
                <p className="text-gray-600">
                  *Total charges many vary due to actual mileage and time
                </p>
              </div>
              <div className="flex xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col justify-between items-center md:gap-5">
                <Link
                  to="/contact"
                  className="btn w-64 xs:w-48 bg-primary text-center content-center text-white xl:text-xl lg:text-lg md:text-md sm:text-sm  rounded hover:text-accent hover:bg-opacity-95 shadow-sm hover:shadow-xl"
                >
                  Accept Quote
                </Link>
                <button
                  className="btn w-64 sm:w-48 xs:w-48 bg-primary text-center text-white xl:text-xl lg:text-lg md:text-md sm:text-sm  rounded hover:text-accent hover:bg-opacity-95 shadow-sm hover:shadow-xl"
                  style={{ cursor: "pointer" }}
                  onClick={handleNewQuote}
                >
                  New Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuoteModal;
