import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import SidePict from "../../assets/images/5thWheelTowing.jpg";
import Title from "../../components/Title/Title";
import { useDistance } from "../../Context/Context";
import ContactInfo from "../../components/ContactInfo/ContactInfo";

const ContactMe = () => {
  const {
    totalCost,
    selectedAdditionalServices,
    selectedService,
    distance,
    selectedLockoutServiceOut,
    selectedLockoutService,
  } = useDistance();

  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [sent, setSent] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs

      .sendForm(
        "service_zsz6i6m",
        "template_tbk5spd",
        form.current,
        "vbm99YwU8yq-3ixcZ"
      )
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
          form.current.reset();
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
          setSent(true);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16 px-12 py-10 sm:px-6 xs:px-6 ">
        <div className="flex flex-col justify-center">
          <div className="hidden lg:block mb-6">
            <img src={SidePict} alt="Contact" className="rounded shadow-xl" />
          </div>
          <ContactInfo />
        </div>
        <div className="flex flex-col border-none border-2 rounded-lg shadow-xl shadow-green-800 items-center bg-white justify-center lg:col-span-1 px-8 py-6 sm:py-2 xs:py-2 sm:px-4 xs:px-1">
          <Title text="Contact Us Today..." />
          {totalCost > 0 ? (
            <p style={{ color: "red" }}>
              Please fill out contact form to submit quote
            </p>
          ) : null}
          <p className="p px-6 lg:text-lg sm:text-sm xs:text-xs text-center first-line:tracking-widest first-letter:text-2xl first-letter:font-bold first-letter:text-slate-900 text-pretty first-line:uppercase sm:px-2 xs:px-2 sm:pb-2 xs:pb-2">
            Whether you're relocating across town or across state, trust Dyer
            Towing & Services for reliable and efficient service. Contact us
            today to schedule your trailer transport and enjoy peace of mind on
            the road ahead!
          </p>
          <form
            className="flex flex-col space-y-4 w-full px-6 sm:p-2 xs:p-2"
            onSubmit={handleSubmit}
            ref={form}
          >
            <input
              className="input"
              type="text"
              name="name"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              className="input"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email"
            />
            <input
              className="input"
              type="text"
              name="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              placeholder="Subject"
            />
            <textarea
              className="textarea"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              placeholder="Message"
            ></textarea>
            <input
              style={{ display: "none" }}
              className="input"
              type="text"
              name="service"
              value={selectedService}
            />
            <input
              style={{ display: "none" }}
              className="input"
              type="text"
              name="add_service"
              value={selectedAdditionalServices}
            />
            <input
              style={{ display: "none" }}
              className="input"
              type="text"
              name="lockout_serviceIn"
              value={selectedLockoutService}
            />
            <input
              style={{ display: "none" }}
              className="input"
              type="text"
              name="lockout_serviceOut"
              value={selectedLockoutServiceOut}
            />
            <input
              style={{ display: "none" }}
              className="input"
              type="text"
              name="miles"
              value={distance}
            />
            <input
              style={{ display: "none" }}
              className="input"
              type="text"
              name="totalCost"
              value={totalCost}
            />
            <button
              type="submit"
              className="btn text-xl text-white rounded hover:text-accent hover:bg-opacity-95 bg-primary shadow-sm hover:shadow-lg"
            >
              Send
            </button>
          </form>
          {sent && <p className="text-green-600">Email sent successfully!</p>}
        </div>
      </div>
    </>
  );
};

export default ContactMe;
