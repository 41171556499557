import React from "react";
import { useDistance, pricing } from "../../Context/Context";
import Title from "../../components/Title/Title";

const QuoteForm = ({ updateDistance }) => {
  const {
    distance,
    setShowQuoteModal,
    selectedService,
    setSelectedService,
    selectedAdditionalServices,
    setSelectedAdditionalServices,
    selectedLockoutService,
    setSelectedLockoutService,
    setTotalQuote,
    selectedLockoutServiceOut,
    setSelectedLockoutServiceOut,
  } = useDistance();

  const {
    basicSvc,
    allInclusiveSvc,
    axleCheck,
    roadSideFlat,
    utiltySvc,
    lockoutSvc,
    lockoutSvcOut,
  } = pricing;

  const handleSubmit = (e) => {
    e.preventDefault();
    updateDistance();

    let selSvcCount = 0;
    let selAddSvcCount = 0;
    let selLockSvcCount = 0;

    if (selectedService === "Basic Transport") {
      selSvcCount += basicSvc;
    } else if (selectedService === "All Inclusive Transport") {
      selSvcCount += allInclusiveSvc;
    }

    if (selectedAdditionalServices && selectedAdditionalServices.length > 0) {
      selectedAdditionalServices.forEach((service) => {
        if (service === "Axle Check") {
          selAddSvcCount += axleCheck;
        }
        if (service === "Road Side Flat") {
          selAddSvcCount += roadSideFlat;
        }
        if (service === "Utility Service") {
          selAddSvcCount += utiltySvc;
        }
      });
    }

    if (selectedLockoutService) {
      selLockSvcCount += lockoutSvc;
    }

    if (selectedLockoutServiceOut) {
      selLockSvcCount += lockoutSvcOut;
    }

    let total = selSvcCount + selAddSvcCount + selLockSvcCount;
    setTotalQuote(total);
    setShowQuoteModal(true);
  };

  const handleServiceChange = (value) => {
    setSelectedService(value);
  };

  const handleAdditionalServicesChange = (value) => {
    const index = selectedAdditionalServices.indexOf(value);
    if (index === -1) {
      setSelectedAdditionalServices([...selectedAdditionalServices, value]);
    } else {
      setSelectedAdditionalServices(
        selectedAdditionalServices.filter((service) => service !== value)
      );
    }
  };

  const handleLockoutServiceChange = () => {
    setSelectedLockoutService(!selectedLockoutService);
  };

  const handleLockoutServiceChangeOut = () => {
    setSelectedLockoutServiceOut(!selectedLockoutServiceOut);
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col border-none border-2 rounded-lg shadow-xl shadow-green-800 items-center bg-white justify-center xl:col-span-1 lg:col-span-2 md:col-span-2 sm:col-1 xs:col-1 lg:px-8 md:px-4 lg:m-10 md:m-6 xs:m-0 pb-6">
          <div className="w-full">
            <Title text="Get a Quote" />
            <p className="p indent-8 xs:indent-0 sm:indent-0 text-center p-4 first-line:tracking-widest first-letter:text-2xl first-letter:font-bold first-letter:text-slate-900 text-pretty first-line:uppercase">
              Need your Trailer moved? Whether it's Tongue Pull, Fifth Wheel or
              Gooseneck we got you covered. We offer competitive quotes tailored
              to your specific needs. Fill out our form below to get an
              estimated quote. Don't like forms and want to speak with a person
              directly? Give us a call and get your personalized quote today!
              Let us help you get where you want to be.
            </p>
            {distance.length > 0 ? (
              <p className="text-center p-2 xl:text-xl lg:text-xl md:text-lg sm:text-sm xs:text-sm font-medium">
                <span className="text-green-600">Traveling Distance:</span>
                <span className="p-2">{distance}</span>
              </p>
            ) : (
              <p className="text-center text-green-600 xl:text-xl lg:text-xl md:text-lg sm:text-sm xs:text-sm p-4">
                Distance will be populated by map
              </p>
            )}
          </div>
          <form className="flex flex-col items-center" onSubmit={handleSubmit}>
            <h3 className="text-green-600 xl:text-xl md:text-lg sm:text-sm xs:text-sm text-center px-4">
              Please Enter Pick up and Drop off location below
            </h3>
            <div className="flex xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col py-6 sm:py-2 xs:py-2 gap-3 sm:gap-1 xs:gap-1 px-4 sm:px-2 xs:px-2">
              <div className="flex items-center">
                <label htmlFor="start" className="text-center"></label>
                <input
                  type="text"
                  id="start"
                  placeholder="Pick Up Address"
                  className="input shadow-md xl:text-lg lg:text-md sm:text-sm xs:text-xs text-center w-72 "
                />
              </div>
              <div className="flex items-center">
                <label htmlFor="end" className="text-center"></label>
                <input
                  type="text"
                  id="end"
                  placeholder="Drop Off Address"
                  className="input shadow-md xl:text-lg lg:text-md sm:text-sm xs:text-xs text-center w-72 "
                />
              </div>
            </div>
            <div className="mb-4">
              <fieldset className="col-span-4 flex flex-row gap-5 sm:gap-2 xs:gap-2">
                <legend className="text-center mb-4">
                  <span className="text-green-600 xl:text-xl md:text-lg sm:text-md">
                    Please Select Service Type
                  </span>
                </legend>
                <div className="flex flex-row items-center gap-1 px-1">
                  <input
                    type="checkbox"
                    id="basicTransport"
                    name="service"
                    value="Basic Transport"
                    checked={selectedService === "Basic Transport"}
                    onChange={() => setSelectedService("Basic Transport")}
                  />
                  <label
                    className="flex flex-row gap-1 xl:text-lg lg:text-lg md:text-md sm:text-sm xs:text-xs items-center"
                    htmlFor="basicTransport"
                  >
                    Basic
                  </label>
                </div>
                <div className="flex flex-row items-center gap-1 px-2">
                  <input
                    type="checkbox"
                    id="inclusiveTransport"
                    name="service"
                    value="All Inclusive Transport"
                    checked={selectedService === "All Inclusive Transport"}
                    onChange={() =>
                      setSelectedService("All Inclusive Transport")
                    }
                  />
                  <label
                    className="flex flex-row gap-1 xl:text-lg lg:text-lg md:text-md sm:text-sm xs:text-xs items-center"
                    htmlFor="inclusiveTransport"
                  >
                    All Inclusive
                  </label>
                </div>
                <div className="flex flex-row items-center gap-1 px-1">
                  <input
                    type="checkbox"
                    id="lockoutService"
                    name="service"
                    value="Lockout Service"
                    checked={selectedService === "Lockout Service"}
                    onChange={() => setSelectedService("Lockout Service")}
                  />
                  <label
                    className="flex flex-row gap-1 xl:text-lg lg:text-lg md:text-md sm:text-sm xs:text-xs items-center"
                    htmlFor="lockoutService"
                  >
                    Lockout
                  </label>
                </div>
              </fieldset>
            </div>

            {selectedService === "Basic Transport" ||
            selectedService === "All Inclusive Transport" ? (
              <div className="mb-4">
                <fieldset className="col-span-4 flex flex-row gap-5 sm:gap-2 xs:gap-2">
                  <legend className="text-center xl:text-xl lg:text-lg sm:text-md xs:text-sm mb-5">
                    <span className="text-green-600">
                      Please Select Additional Services
                    </span>
                  </legend>
                  <div className="flex flex-row items-center gap-1 px-1 xs:px-0 xs:gap-0">
                    <input
                      type="checkbox"
                      id="axleCheck"
                      value="Axle Check"
                      checked={selectedAdditionalServices.includes(
                        "Axle Check"
                      )}
                      onChange={() =>
                        handleAdditionalServicesChange("Axle Check")
                      }
                    />
                    <label
                      className="flex flex-row gap-1 xl:text-lg lg:text-lg md:text-md sm:text-sm xs:text-xs items-center"
                      htmlFor="axleCheck"
                    >
                      Axle Check
                    </label>
                  </div>
                  <div className="flex flex-row items-center gap-1 px-2 xs:px-1 xs:gap-0">
                    <input
                      type="checkbox"
                      id="roadSideFlat"
                      value="Road Side Flat"
                      checked={selectedAdditionalServices.includes(
                        "Road Side Flat"
                      )}
                      onChange={() =>
                        handleAdditionalServicesChange("Road Side Flat")
                      }
                    />
                    <label
                      className="flex flex-row gap-1 xl:text-lg lg:text-lg md:text-md sm:text-sm xs:text-xs items-center"
                      htmlFor="roadSideFlat"
                    >
                      Road Side Flat
                    </label>
                  </div>
                  <div className="flex flex-row items-center gap-1 px-1 xs:px-0 xs:gap-0">
                    <input
                      type="checkbox"
                      id="utilityService"
                      value="Utility Service"
                      checked={selectedAdditionalServices.includes(
                        "Utility Service"
                      )}
                      onChange={() =>
                        handleAdditionalServicesChange("Utility Service")
                      }
                    />
                    <label
                      className="flex flex-row gap-1 xl:text-lg lg:text-lg md:text-md sm:text-sm xs:text-xs items-center"
                      htmlFor="utilityService"
                    >
                      Utility Service
                    </label>
                  </div>
                </fieldset>
              </div>
            ) : null}

            {selectedService === "Lockout Service" ? (
              <div className="mb-4">
                <fieldset className="col-span-4">
                  <legend className="text-center xl:text-lg lg:text-lg md:text-lg sm:text-md xs:text-sm mb-4 text-green-600">
                    Please Select where vehicle is located
                  </legend>
                  <div className="flex flex-row gap-5 sm:gap-2 xs:gap-2 items-center px-4">
                    <div className="flex flex-row items-center gap-1 px-2 xs:px-1">
                      <input
                        type="checkbox"
                        id="lockoutServiceOut"
                        checked={selectedLockoutServiceOut}
                        onChange={handleLockoutServiceChangeOut}
                      />
                      <label
                        className="xl:text-lg lg:text-lg md:text-md sm:text-sm xs:text-xs"
                        htmlFor="lockoutServiceOut"
                      >
                        Outside Freestone
                      </label>
                    </div>
                    <div className="flex flex-row items-center gap-1 px-2 xs:px-1">
                      <input
                        type="checkbox"
                        id="lockoutServiceIn"
                        checked={
                          selectedLockoutService && !selectedLockoutServiceOut
                        }
                        onChange={handleLockoutServiceChange}
                      />
                      <label
                        className="xl:text-lg lg:text-lg md:text-md sm:text-sm xs:text-xs"
                        htmlFor="lockoutServiceIn"
                      >
                        Inside Freestone
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
            ) : null}

            <div className="mb-4">
              <div className="col-span-2">
                {selectedService ? (
                  <button
                    type="submit"
                    className="btn text-lg text-white rounded hover:text-accent hover:bg-opacity-95 bg-primary shadow-sm hover:shadow-lg lg:w-96 md:w-72 sm:w-72 xs:w-72"
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    title="Enter Location & Choose A Service"
                    type="submit"
                    className="btn border-2 bg-gray-300 shadow-sm text-lg text-center font-medium text-gray-800 hover:bg-gray-400 hover:shadow-lg p-2 rounded cursor-not-allowed lg:w-96 md:w-72 sm:w-72 xs:w-72"
                    disabled={true}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default QuoteForm;
